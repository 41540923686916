/*
 * Public API Surface of @coreui/angular
 */

export * from './lib/aside/index';
export * from './lib/breadcrumb/index';
export * from './lib/footer/index';
export * from './lib/header/index';
export * from './lib/sidebar/index';
// export * from './lib/shared/index';
// export * from './lib/coreui.module';
